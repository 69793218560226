import request from '@/utils/request'
import { AxiosPromise } from 'axios'

// 获取搜索的城市数据
export const fectCityDataApi = (param): AxiosPromise => {
    return request('HotelSearchService.ashx', param)
}

// 获取酒店列表数据
export const fectHotelListApi = (param): AxiosPromise => {
    return request('HotelSearchService.ashx', param)
}

//获取位置项数据
export const fectLocationApi = (param): AxiosPromise => {
    return request('HotelSearchService.ashx', param)
}

//获取酒店详情
export const fectHotelDetailApi = (param): AxiosPromise => {
    return request('HotelSearchService.ashx', param)
}

// 订单
export const fectHotelOrder = (param): AxiosPromise => {
    return request('OrderService.ashx', param)
}

// 订单付款
export const fectHotelOrderPay = (param): AxiosPromise => {
    return request('payOrderService.ashx', param)
}
